<template>
  <div id="app">
      <router-view />
  </div>
</template>
<script>
export default {
};
</script>
<!-- -->
<style lang="scss" >
body {
  margin: 0px;
}
.Welcome {
  font-size: 18px;
font-weight: 500;
letter-spacing: 0px;
line-height: 26.06px;
color: rgba(51, 51, 51, 1);
  padding: 15px 0px ;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Welcome_line {
  width: 38px;
  height: 3px;
  border-radius: 10px;
  margin-top: 5px;
  background: linear-gradient(90deg, rgba(120, 159, 204, 1) 0%, rgba(35, 115, 187, 1) 100%);
}
.fx{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mb{
  margin-bottom: 20px;
}
.fxcolumn{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.xmcolor{
  width: 45px;
  // background: #350D6B;
  border-top-left-radius: 10px;
}
.xxcolor{
  width: 45px;
  // background: #73b0e4;
  border-bottom-left-radius: 10px;
}
.textpad{
  padding: 0px  5px;
  box-sizing: border-box;
}
.pd{
  padding: 20px 10px;
  box-sizing: border-box;
  div{
    margin-bottom: 15px;
  }
  
}
.flex-end{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>